import React from 'react';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { InfoCard } from '../partials/InfoCard'
import { validatePnr, normalizePnr } from '../../utils/personnumber'
import NavBar from '../partials/NavButtons/NavBar'

const path = require('path');
require('dotenv').config({ path: path.resolve(__dirname, '.env') });
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default class Search extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			SSN: "",
			PID: "",
			dataList: null,
		}

		this.loadPerson = this.loadPerson.bind(this);
	}

	async loadPerson() {
		if (!this.state.SSN && !this.state.PID) {
			alert("No SSN or PID, no load will be performed")
			return
		}

		const accessToken = this.props.token
		const config = {
			method: 'GET',
			url: `${BASE_URL}/load/person`,
			headers: {
				'Content-Type': 'application/json',
				'ssn': this.state.SSN,
				'pid': this.state.PID,
				Authorization: `Bearer ${accessToken}`
			},
		};

		axios(config).then((res) => {
			this.setState({ dataList: res.data })
		}, (error) => {
			console.log("error", error);
		});
	}

	async archive(id) {
			const accessToken = this.props.token
			const config = {
				method: 'GET',
				url: `${BASE_URL}/archive`,
				headers: {
					id:id,
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				} 
			};
			await axios(config) 
	}

	updateSSN(event) {
		const input = event.target.value;
		// Check if valid SSN
		const isValid = validatePnr(input)
		if (isValid) {
			const personNumber = normalizePnr(input)
			this.setState({ SSN: personNumber })
		} else {
			this.setState({ SSN: null })
		}
	}

	updatePID(event) {
		const input = event.target.value;
		// Check if valid SSN
		this.setState({ PID: input })
	}

	render() {

		return (
			<React.Fragment>
				<NavBar isAdmin={this.props.isAdmin}/>
				<main className={classes.layout}>
					<Paper className={classes.paper} style={{ width: 1000 }}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={2}></Grid>
							<Grid item xs={12} sm={8}>
								<Typography component="h1" variant="h4" align="center" style={{ paddingTop: 20 }}>Beräkningsstöd för tid i hemtjänsten</Typography>
							</Grid>
							<Grid item xs={12} sm={2} />
						</Grid>
						<React.Fragment>
							<div style={{ marginTop: 20 }}>
								<TextField
									id="outlined-basic"
									label="Personnummer"
									variant="outlined"
									onChange={(event) => this.updateSSN(event)}
								/>
								<TextField
									style={{ marginLeft: 20 }}
									id="outlined-basic"
									label="Process id"
									variant="outlined"
									onChange={(event) => this.updatePID(event)}
								/>
							</div>
						</React.Fragment>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={3} />
								<Grid item xs={12} sm={3} />
							</Grid>
						<React.Fragment>
							<div style={{ marginTop: 20, paddingBottom: 20 }}>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={4}></Grid>
									<Grid item xs={12} sm={8}>
									</Grid>
								</Grid>
								<Button variant="outlined" color="primary" onClick={() => this.loadPerson()}>Sök</Button>
							</div>
							<div >
								{this.state.dataList && !this.state.dataList.length &&
									<div>Inga träffar</div>
								}
								{this.state.dataList && this.state.dataList.map((obj, index) => (
									<InfoCard archive={async (id) => { await this.archive(id); await this.loadPerson() }} key={index} data={obj} raised={true}></InfoCard>
								))}
							</div>
						</React.Fragment>
					</Paper>
				</main>
			</React.Fragment>
		)
	}
}



const classes = theme => ({
	appBar: {
		position: 'relative',
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 1200,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
	},
});