import React, {
    useEffect,
    useState
} from 'react';
import {
    useNavigate,
    useLocation
} from 'react-router-dom'
import axios from 'axios';
import { Button, Input, Typography } from '@material-ui/core';
const BASE_URL = process.env.REACT_APP_BASE_URL;


export const useBankid = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [loading, setLoading] = useState(true)
    const [isAdmin, setIsAdmin] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()

    const testAuthentication = async (token) => {
        return axios({
            method: 'GET',
            url: `${BASE_URL}/test/auth`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    useEffect(() => {
        if (sessionStorage.getItem("bearerToken")) {
            const token = sessionStorage.getItem("bearerToken")
            testAuthentication(token).then((x) => {
                setIsAuthenticated(true)
                setIsAdmin(x.data.role === "admin")
                setLoading(false)
                sessionStorage.setItem("bearerToken", token)
            }).catch(() => {
                setIsAuthenticated(false)
                setLoading(false)
                sessionStorage.removeItem("bearerToken")
            })
        } else {
            setIsAuthenticated(false)
            setLoading(false)
        }
    }, [])


    const initiateLogin = async (personalNumber) => {
        const initiaion = await axios({
            method: 'POST',
            url: `${BASE_URL}/auth/bankid/initiatebankid`,
            data: {
                pno: personalNumber
            }
        })

        console.log(initiaion.data.ref)
        setLoading(true)

        const inter = setInterval(async () => {
            const collection = await axios({
                method: 'GET',
                url: `${BASE_URL}/auth/bankid/collectbankid?ref=${initiaion.data.ref}`
            }) 

            if(collection.data.status==="complete"){
                sessionStorage.setItem("bearerToken", collection.data.jwtSign)
                clearInterval(inter)
                setIsAuthenticated(true)
                setLoading(false)
                setIsAdmin(collection.data.role === "admin")

            }
        }, 3000);
    }

    return {
        isAuthenticated,
        loading,
        initiateLogin,
        isAdmin
    }
}

export const SignInBankId = ({setPersonNumber, personalNumber, initiateLogin, loading}) => {

    const login = async () => {
        const person = await initiateLogin(personalNumber)
    }

    if(loading){
        return (
            <>
                 <div style={{textAlign:"center", marginTop: 40}}>
                    <Typography variant="h3">Logga in med BankId på din enhet</Typography>
                    <img src={'/BankID_logo_neg.png'} style={{width:140, marginTop:40}} />
                </div>
            </>
        )
    }

    return (
        <>
          <div className='login'>
            <Typography variant="h3">Beslutsstöd - Inrikta</Typography>
            <Typography variant="p">Logga in med bankid genom att fylla i ditt personnummer nedan. 12 siffror.</Typography>
            <div class="login-section">
              <Input placeholder='19780101xxxx' onChange={(e) => setPersonNumber(e.target.value)} value={personalNumber} />
            </div>
            <div class="login-section">
              <Button variant='outlined' onClick={() => login()}>Logga in</Button>
            </div>
            <img src={'/BankID_logo_neg.png'} style={{width:120, marginTop:20}} />

          </div>
        </>
    )
}
