import * as React from 'react'
import { Typography, Checkbox, TextField, Button, FormControlLabel } from '@material-ui/core'
import NavBar from '../../partials/NavButtons/NavBar'
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const EditUser = (props) => {
    const [loading, setLoading] = React.useState(false)
    const [name, setName] = React.useState("")
    const [personNumber, setPersonNumber] = React.useState("")
    const [admin, setAdmin] = React.useState(false)

    const accessToken = props.token;

    const createUser = async () => {
        setLoading(true)
        toast("Skapar användare...")

        const usersConfig = {
            method: "post",
            url: `${BASE_URL}/users/new`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: {
                personNumber:personNumber,
                name:name,
                role: admin ? "admin" : "user"
            }
          };
          
          const user = await axios(usersConfig)
          if(user.status === 200){
            toast("Användare skapad")
            setName("")
            setPersonNumber("")
            setAdmin(false)
          } else {
            toast("Misslyckades med att skapa användare")
          }
          setLoading(false)
    }

    return (
        <div style={{width:1000}}>
            <ToastContainer />
            <NavBar isAdmin={props.isAdmin} />
            <div>
            <Typography
                component="h1"
                variant="h4"
                align="center"
                style={{ padding: 20, color:"black" }}
                >
                    Lägg till ny användare
                </Typography>
            </div>
            <div className='userinput'>
                <FormControlLabel
                    control={
                        <TextField
                          variant="outlined"
                          id={"beta"}
                          label={"Namn"}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />                    
                    }
                    label={"Ex: Förnamn Efternamn"}
                    labelPlacement="bottom"
                    style={{color:"black"}}
                />
            </div>
            <div className='userinput'>
                <FormControlLabel
                    control={
                        <TextField
                          variant="outlined"
                          id={"pnumber"}
                          label={"Personnummer"}
                          value={personNumber}
                          onChange={(e) => setPersonNumber(e.target.value)}
                        />                    
                    }
                    label={"Ex: 198209270000"}
                    labelPlacement="bottom"
                    style={{color:"black"}}
                />
            </div>
            <div className='userinput'>
                <FormControlLabel
                    value="end"
                    style={{color:"black"}}
                    control={
                        <Checkbox                        
                            color="primary"
                            label={"asd"}
                            checked={admin}
                            onChange={(e)=>setAdmin(!admin)}
                        />
                    }
                    label={"Kan hantera användare"}
                    labelPlacement="end"
                />
            </div>
            <div className='userinput'>
                    <Button onClick={createUser} variant="outlined">Skapa användare</Button>
            </div>
        </div>
    )
}

export default EditUser