import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormPage from "../partials/FormPage";
import CheckboxPage from "../partials/CheckboxPage";
import axios from "axios";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Question } from "../resources/img/question.svg";
import { connect } from "react-redux";
import NavBar from '../partials/NavButtons/NavBar'
import {
  addAllData,
  addInput,
  changeTimepoints,
  changeSelectedMunicipaly,
} from "../../actions/backendDataAction";

import {
  setTimepointTasks,
  setExtraTask,
  updateWeeksOff,
  updateShared,
  setCheckboxes,
  setAllTimepointTasks,
} from "../../actions/answerModelAction";

import { saveAs } from "file-saver";
import { validatePnr, normalizePnr } from "../../utils/personnumber";

const path = require("path");
require("dotenv").config({ path: path.resolve(__dirname, ".env") });

const showOnlyTotalTime = process.env.REACT_APP_SHOW_ONLY_TOTAL_TIME === "true"

// Set the correct URL when calling APIs
const BASE_URL = process.env.REACT_APP_BASE_URL;


const classes = (theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 1200,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

class Client extends React.Component {
  constructor(props) {
    super(props);
    console.log("propp", props)
    this.handleClick = this.handleClick.bind(this);
  }
  state = {
    answerModel: {},
    activeSteps: [],
    timePerTimepoint: {},
    draft: true,
    editTime: false,
    selectKey: "",
    showErrorModal: false,
    SSN: null,
    PID: "",
    search: false,
    hasUpdatedShared: false
  };
  

  handleClick(value) {
    const navigate = useNavigate();
    navigate("/search");
  }

  componentWillMount() {
    this.getAllData();
  }

  getFrequency(frequency) {

    switch(Math.round(frequency * 100)/100){
      case 0:
        return "-"
      case 28:
        return "7 gånger i veckan"
      case 24:
        return "6 gånger i veckan"
      case 20:
        return "5 gånger i veckan"
      case 16:
        return "4 gånger i veckan"
      case 12:
        return "3 gånger i veckan"
      case 8:
        return "2 gånger i veckan"
      case 4:
        return "1 gång i veckan"
      case 2:
        return "Varannan vecka"
      case 1.33:
        return "Var tredje vecka"
      case 1:
        return "Var fjärde vecka"
    }
  }

  setExtraTasksd(fromPerson) {
    const defTimePointTasks = (fromPerson && fromPerson.state) ? fromPerson.state.extraTasks : {}
    let answer = {};
    const { selectedMunicipaly } = this.props.backendDataReducer;
    const { extraTasks } = this.props.backendDataReducer.data[selectedMunicipaly];
    extraTasks &&
      extraTasks.map((extraTask, index) => {
        if (defTimePointTasks[extraTask.name]) {
          console.log(defTimePointTasks[extraTask.name])
          answer[extraTask.name] = {
            frequency: this.getFrequency(defTimePointTasks[extraTask.name].frequency),
            selection: defTimePointTasks[extraTask.name].selection,
            double: defTimePointTasks[extraTask.name].double
          };
        } else {
          answer[extraTask.name] = {
            frequency: extraTask.hasFrequency ? "-" : "-",
            selection: extraTask.selections[0],
          };
        }

      });

      console.log("this is answer", answer)

    this.props.setExtraTask(answer);
  }

  setInitialCheckboxes() {
    let answer = {};
    const { selectedMunicipaly } = this.props.backendDataReducer;
    const { checkboxes } =
      this.props.backendDataReducer.data[selectedMunicipaly];
    if (checkboxes) {
      Object.keys(checkboxes).map((checkbox) => {
        answer[checkbox] = false;
      });
    }


    this.props.setCheckboxes(answer);
  }

  setInitialTimePoints(fromPerson) {
    const defTimePointTasks = (fromPerson && fromPerson.state) ? fromPerson.state.timepointTasks : {}

    Object.keys(defTimePointTasks).map(x => {
      this.state.activeSteps.push(x);
    })

    const { selectedMunicipaly } = this.props.backendDataReducer;
    const { timepoints, timepointTasks } = this.props.backendDataReducer.data[selectedMunicipaly];

    let result = {};
    timepoints.map((timepoint) => {
      let answer = {};
      timepointTasks.map((timepointTask, index) => {
        if (defTimePointTasks[timepoint] && defTimePointTasks[timepoint][timepointTask.name]) {
          answer[timepointTask.name] = {
            frequency: defTimePointTasks[timepoint][timepointTask.name].frequency,
            selection: defTimePointTasks[timepoint][timepointTask.name].selection,
            double: defTimePointTasks[timepoint][timepointTask.name].double
          };
        } else {
          answer[timepointTask.name] = {
            frequency: 0,
            selection: timepointTask.selections[0],
            double: false
          };
        }

      });
      result[timepoint] = answer;
    });
    this.props.setAllTimepointTasks(result);

  }

  async getAllData() {
    const accessToken = this.props.token;
    const config1 = {
      method: "GET",
      url: `${BASE_URL}/person`,
      headers: {
        "Content-Type": "application/json",
        id: this.props.id,
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const a = await axios(config1)
    let fromPerson;
    if(a.data.length > 0){
      fromPerson = JSON.parse(a.data[0].input_state)
    }

    this.setState({fromPerson:fromPerson})
    

    // GET request doesnt support data/body so we need to send name in the header (or change to a post request)
    const config = {
      method: "GET",
      url: `${BASE_URL}/municipality`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios(config).then(
      (res) => {
        this.props.addInput(res.data.name, res.data);
        this.props.changeSelectedMunicipaly(res.data.name);
        this.setExtraTasksd(fromPerson);
        this.setInitialCheckboxes();
        this.setInitialTimePoints(fromPerson);
        this.props.updateWeeksOff(0);
      },
      (error) => {
        console.log("Error while fetching all data", error);
      }
    ); 
  }

  getHours = (num) => {
    return Math.floor(num);
  };

  getMinutes = (num) => {
    return Math.floor((num - this.getHours(num)) * 60);
  };

  createTimeString = (hours) => {
    return "" + this.getHours(hours) + "h " + this.getMinutes(hours) + "min";
  };

  stripNotSelected(data) {
    let newTimepointsTasks = {};
    const timePointTasks = data.timepointTasks;
    Object.keys(timePointTasks).map((key) => {
      if (this.state.activeSteps.includes(key)) {
        newTimepointsTasks = {
          ...newTimepointsTasks,
          [key]: timePointTasks[key],
        };
      }
    });

    return { ...data, timepointTasks: newTimepointsTasks };
  }


  parseFrekvens(data) {
    let extra = {};
    const converter = {
      "-": 0,
      "7 gånger i veckan": 7*4,
      "6 gånger i veckan": 6*4,
      "5 gånger i veckan": 5*4,
      "4 gånger i veckan": 4*4,
      "3 gånger i veckan": 3*4,
      "2 gånger i veckan": 2*4,
      "1 gång i veckan": 1*4 ,
      "Varannan vecka": 0.5 * 4,
      "Var tredje vecka": 4 / 3,
      "Var fjärde vecka": 4 / 4
    };
    const { extraTasks } = data;
    console.log(extraTasks)
    Object.keys(extraTasks).map((key) => {
      extra = {
        ...extra,
        [key]: {
          ...extraTasks[key],
          "frequency": converter[extraTasks[key].frequency]
        },
      };

    });


    const result = {
      ...data,
      extraTasks: extra,
    };
    return result;
  }

  async performCalculation() {
     const data = this.parseFrekvens(this.props.answerModelReducer);
     const result = this.stripNotSelected(data);
     const accessToken = this.props.token
 
     const config = {
       method: "POST",
       url: `${BASE_URL}/calculate`,
       headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${accessToken}`,
       },
       data: {
         result,
       },
     };
 
     axios(config).then(
       (res) => {
         this.setState({
           hoursPerWeekPerTimepoint: res.data.hoursPerWeekPerTimepoint,
           totalExDouble: res.data.totalExDouble,
           hoursPerMonthTimePointsTasksDouble: res.data.hoursPerMonthTimePointsTasksDouble,
           hoursPerMonthTimepointTasksDouble: res.data.hoursPerMonthTimePointsTasksDouble,
           hoursPerWeekTimepointTasks: this.createTimeString(
             res.data.hoursPerWeekTimepointTasks
           ),
           hoursPerMonthTimepointTasks: this.createTimeString(
             res.data.hoursPerMonthTimepointTasks
           ),
           hoursPerDayTimepointTasks: this.createTimeString(
             res.data.hoursPerDayTimepointTasks
           ),
           hoursPerMonthExtraTasks: this.createTimeString(
             res.data.hoursPerMonthExtraTasks
           ),
           totalHoursPerMonth: res.data.totalHoursPerMonth,
           hoursPerMonthSpecificExtraTasks:
             res.data.hoursPerMonthSpecificExtraTasks,
         });
       },
       (error) => {
         console.log("error", error);
       }
     );  
  }

  async savePerson() {
     if (!this.state.SSN || !this.state.PID) {
       alert(
         "Inget personnummer eller processid är inskrivet. Detta betyder att resultatet inte kommer att laddas upp till databasen. För att ladda upp klicka på ok, fyll i fälten och klicka på beräkna igen."
       );
       return;
     } 

     this.setState({
      loading:true, 
      error: false,
      saved: false
     })

     const data = this.parseFrekvens(this.props.answerModelReducer);
     const result = this.stripNotSelected(data);

     console.log(result)

     const accessToken = this.props.token;
  
     const config = {
       method: "POST",
       url: `${BASE_URL}/save/person`,
       headers: {
         "Content-Type": "application/json",
         ssn: this.state.SSN,
         pid: this.state.PID,
         draft: this.state.draft,
         Authorization: `Bearer ${accessToken}`,
       },
       data: {
         result,
       },
     };
 
     axios(config).then(
       (res) => {
         // Note: it's important to handle errors here
         // instead of a catch() block so that we don't swallow
         // exceptions from actual bugs in components.
         this.setState({
          loading:false, 
          error: false,
          saved: true
         })
       },
       (error) => {
        this.setState({loading:false, error:true, saved:false})

         console.log("error", error);
       }
     ); 
  }

  timeStepCheckboxClicked = (key) => {
    let i = this.state.activeSteps.indexOf(key);
    if (i < 0) {
      this.state.activeSteps.push(key);
    } else {
      this.state.activeSteps.splice(i, 1);
    }



    this.setState({
      update: !this.state.update,
    });
  };

  renderCheckBoxes(data) {
    return data.map((key) => {
      return (
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              checked={this.state.activeSteps.includes(key)}
              color="primary"
              onChange={() => {
                this.timeStepCheckboxClicked(key);
              }}
            />
          }
          label={key}
          labelPlacement="end"
        />
      );
    });
  }

  renderStepContent(timepoints, timepointTasks) {
    return timepoints.map((step, i) => {
      if (this.state.activeSteps.indexOf(step) >= 0) {
        return (
          <FormPage
            dontShowLevels={false}
            title={timepoints[i]}
            values={timepointTasks}
            client
          />
        );
      }
    });
  }

  renderTimepoints() {
    const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
    const { showTimepoints } =
      this.props.backendDataReducer.settings[selectedMunicipaly]
        .calculationOutputSettings;
    if (this.state.hoursPerWeekPerTimepoint && showTimepoints) {
      const timepoints = this.state.hoursPerWeekPerTimepoint;
      return Object.keys(timepoints).map((key) => {
        return (
          <Typography>
            Tid för {key} per vecka: {this.createTimeString(timepoints[key])}
          </Typography>
        );
      });
    }
  }

  renderSpecificExtraPoints() {
    if (this.state.hoursPerMonthSpecificExtraTasks) {
      const extraTasks = this.state.hoursPerMonthSpecificExtraTasks;
      return Object.keys(extraTasks).map((key) => {
        return (
          <Typography>
            Tid för {key} per månad: {this.createTimeString(extraTasks[key])}
          </Typography>
        );
      });
    }
  }

  timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return  rhours + "h " + rminutes + " min";
    }
    

  renderSummary() {
    if(showOnlyTotalTime){
      return (
        <Typography
        variant="subtitle1"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginTop: 20,
        }}
      >
       {this.state.totalExDouble ? (
          <>
            <Typography>
              <b>Total tid: {this.timeConvert(this.state.totalExDouble * 60)}</b>
            </Typography>
            <br />
          </>

        ) : null}
      </Typography>
      )
    }
    return (
      <Typography
        variant="subtitle1"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginTop: 20,
        }}
      >

        {this.state.totalExDouble ? (
          <>
            <Typography>
              <b>Total tid exklusive dubbelbemanning: {this.createTimeString(this.state.totalExDouble)} ({(Math.floor(this.state.totalExDouble * 60))} minuter)</b>
            </Typography>
            <br />
          </>

        ) : null}
        {this.state.hoursPerMonthTimePointsTasksDouble ? (
          <>
            <Typography>
              <b>Total tid dubbelbemanning: {this.createTimeString(this.state.hoursPerMonthTimePointsTasksDouble)} ({(Math.floor(this.state.hoursPerMonthTimePointsTasksDouble * 60))} minuter)</b>
            </Typography>
            <br />
          </>

        ) : null}
        {this.state.totalHoursPerMonth ? (
          <>
            <Typography>
              Total tid per månad (Inklusive dubbelbemanning): {this.createTimeString(this.state.totalHoursPerMonth)} ({(Math.floor(this.state.totalHoursPerMonth * 60))} minuter)
            </Typography>
            <br />
          </>

        ) : null}
        {this.renderTimepoints()}
        {this.state.hoursPerMonthTimepointTasks ? (
          <div>
            <Typography>
              Tid för personlig vård per månad:{" "}
              {this.state.hoursPerMonthTimepointTasks} 
            </Typography>
            <br />
          </div>
        ) : null}

        {this.state.hoursPerMonthExtraTasks ? (
          <div>
            <Typography>
              Tid för övriga insatser per månad:{" "}
              {this.state.hoursPerMonthExtraTasks}
            </Typography>
            <br />
          </div>
        ) : null}
        {this.renderSpecificExtraPoints()}
      </Typography>
    );
  }

  showEditTime() {
    this.setState({
      editTime: !this.state.editTime,
    });
  }



  updateWeeksOff(event) {
    const input = parseInt(event.target.value);
    if (event.target.value === "") {
      this.props.updateWeeksOff("");
    } else if (!Number.isNaN(input)) {
      this.props.updateWeeksOff(parseInt(event.target.value));
    }
  }

  updateShared(event) {
    this.props.updateShared(event.target.checked);
  }

  updateSSN(event) {
    const input = event.target.value;
    // Check if valid SSN
    const isValid = validatePnr(input);
    if (isValid) {
      const personNumber = normalizePnr(input);
      this.setState({ SSN: personNumber });
    } else {
      this.setState({ SSN: null });
    }
  }
  updatePID(event) {
    const input = event.target.value;
    this.setState({ PID: input });
  }

  async createPdf() {
     const data = this.parseFrekvens(this.props.answerModelReducer);
     const result = this.stripNotSelected(data);
     const accessToken = this.props.token
     const config = {
       method: "POST",
       url: `${BASE_URL}/pdf`,
       responseType: "blob",
       headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${accessToken}`,
       },
       data: {
         result,
       },
     };
 
     axios(config)
       .then((res) => {
         var file = new Blob([res.data], {
           type: "application/pdf",
         });
         saveAs(file, "summary.pdf");
       })
       .catch((e) => {
         this.setState({ showErrorModal: true });
         return "Error, no response recieved from server";
       }); 
  }

  saveStateToFile() {
    var file = new Blob([JSON.stringify(this.props.answerModelReducer)], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(file, "state.json");
  }

  render() {
    const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
    const data = this.props.backendDataReducer.data[selectedMunicipaly];
    const settings = this.props.backendDataReducer.settings[selectedMunicipaly];
    const logoUrl = this.props.backendDataReducer.logoUrl[selectedMunicipaly];

    let timepoints;
    let timepointTasks;
    let extraTasks;
    let checkboxes;

    if (data) {
      timepoints = data.timepoints;
      timepointTasks = data.timepointTasks;
      extraTasks = data.extraTasks;
      checkboxes = data.checkboxes;
    }
    const { municipalies } = this.props.backendDataReducer;
    const { weeksOff, shared } = this.props.answerModelReducer;

    if (this.state.fromPerson && this.state.fromPerson.state && this.state.fromPerson.state.shared && !shared && !this.state.hasUpdatedShared) {
      this.props.updateShared(true)
      this.setState({hasUpdatedShared: true})
    }

    return (
      <React.Fragment>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.showErrorModal}
          onClose={() => this.setState({ showErrorModal: false })}
        >
          <Paper
            style={{
              marginTop: 200,
              marginLeft: "auto",
              marginRight: "auto",
              width: 400,
              height: 300,
              zIndex: 2000,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              paddingLeft: 40,
              paddingRight: 40,
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              style={{ paddingTop: 20 }}
            >
              Ett fel har inträffat, <br />
              Något är inte ifyllt korrekt
            </Typography>
            <Button
              style={{ cursor: "pointer", backgroundColor: "white" }}
              variant="outlined"
              color="primary"
              onClick={() => this.setState({ showErrorModal: false })}
            >
              {" "}
              Stäng{" "}
            </Button>
          </Paper>
        </Modal>
        {data ? (
          <React.Fragment>
            <CssBaseline />
            <main className={classes.layout}>
              <NavBar isAdmin={this.props.isAdmin} logo={logoUrl}></NavBar>

              <Paper className={classes.paper} style={{ width: 1000 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={2}></Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography
                      component="h1"
                      variant="h4"
                      align="center"
                      style={{ paddingTop: 20 }}
                    >
                      Beräkningsstöd för tid i hemtjänsten
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} />
                </Grid>
                <React.Fragment>
                  <div style={{ marginTop: 20 }}>
                    {this.renderCheckBoxes(timepoints)}
                  </div>
                  <React.Fragment>
                    {this.renderStepContent(timepoints, timepointTasks)}
                    <FormPage
                      dontShowLevels={false}
                      title={"Övriga insatser"}
                      values={extraTasks}
                      client
                      extraTasks
                    />
                  </React.Fragment>
                </React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}></Grid>
                  <Grid item xs={12} sm={6}>
                    <div style={{ marginTop: 30 }}>
                      <FormControl>
                        {settings.formSettings.showWeeksOff && (

                          <FormControlLabel
                            value="end"
                            control={
                              <TextField
                                value={weeksOff}
                                onChange={(event) => this.updateWeeksOff(event)}
                                id="vaxel"
                                placeholder=""
                              />
                            }
                            label={"veckor växelvård"}
                            labelPlacement="end"
                          />
                        )}

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 20,
                          }}
                        >
                          {settings.formSettings.showShared && (
                            <div>
                              <FormControlLabel
                                value="start"
                                control={
                                  <Checkbox
                                    checked={shared}
                                    color="primary"
                                    onChange={(event) => this.updateShared(event)}
                                  />
                                }
                                label={"Dela med make/maka"}
                                labelPlacement="end"
                              />


                              <Tooltip
                                title={
                                  <React.Fragment>
                                    <Typography variant="caption">
                                      {" "}
                                      Bostadens skösel städ, tvätt, inköp och
                                      tillredning av måltider delas med make/maka?
                                    </Typography>
                                  </React.Fragment>
                                }
                              >
                                <Question />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </FormControl>
                    </div>
                    <CheckboxPage checkboxes={checkboxes} />
                  </Grid>
                  <Grid item xs={12} sm={3}></Grid>
                </Grid>

                <React.Fragment>
                  <div style={{ marginTop: 20, paddingBottom: 20 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        this.performCalculation();
                      }}
                    >
                      Beräkna
                    </Button>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}></Grid>
                      <Grid item xs={12} sm={8}>
                        {this.renderSummary()}
                      </Grid>
                    </Grid>

                    <div style={{ marginTop: 30 }}>
                      <TextField
                        id="outlined-basic"
                        label="Personnummer"
                        variant="outlined"
                        onChange={(event) => this.updateSSN(event)}
                      />
                      <TextField
                        style={{ marginLeft: 20 }}
                        id="outlined-basic"
                        label="Process id"
                        variant="outlined"
                        onChange={(event) => this.updatePID(event)}
                      />
                      <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              checked={
                                this.state.draft
                              }
                              color="primary"
                              onChange={() => {
                                this.setState({ draft: !this.state.draft })
                              }}
                            />
                          }
                          label={"Spara som utkast"}
                          labelPlacement="end"
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            this.savePerson();
                          }}
                        >
                          Spara
                        </Button>
                        {this.state.loading && <p>Sparar...</p> }
                        { this.state.saved && <p>Sparat</p> }
                        {this.state.error && <p>Något gick fel</p> }
                      </div>
                    </div>
               
                   {/* <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => this.createPdf()}
                    >
                      PDF
                   </Button> */}
                  </div>
                </React.Fragment>
              </Paper>
            </main>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ backendDataReducer, answerModelReducer }) => ({
  backendDataReducer,
  answerModelReducer,
});

const mapDispatchToProps = (dispatch) => ({
  addAllData: (data) => dispatch(addAllData(data)),
  addInput: (key, input) => dispatch(addInput(key, input)),
  changeTimepoints: (data) => dispatch(changeTimepoints(data)),
  changeSelectedMunicipaly: (name) => dispatch(changeSelectedMunicipaly(name)),
  setTimepointTasks: (time, timePointTasks) => dispatch(setTimepointTasks(time, timePointTasks)),
  setExtraTask: (taskData) => dispatch(setExtraTask(taskData)),
  updateWeeksOff: (number) => dispatch(updateWeeksOff(number)),
  updateShared: (shared) => dispatch(updateShared(shared)),
  setCheckboxes: (checkboxes) => dispatch(setCheckboxes(checkboxes)),
  setAllTimepointTasks: (timepointTasks) => dispatch(setAllTimepointTasks(timepointTasks)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(classes)(Client));
